import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'app/shared/button';

import styles from './plan.module.css';
import { BasicInfoSection } from 'app/shared/info-section/basic';
import { DetailItem } from 'app/shared/info-section/item';
import { Spacer } from 'app/layouts/generic';
import { useBillingService } from 'hooks/sales/billing';
import { useSubscriptionService } from 'hooks/sales/subscription';
import ConfirmationDialog from 'app/shared/dialogs/confirmation';
import GmModal from 'app/shared/modal/modal';
import { PurchaseSubscription } from '../subscription/purchase';
import { CheckOut } from '../subscription/checkout/checkout';
import { toast } from 'react-toastify';

export const PlanDetails = () => {
  const { rate } = useSelector((state) => state.wallet);
  const [show_confirmation, setShowConfirmation] = useState(false);
  const [show_purchase_sub_modal, setShowPurchaseSub] = useState(false);
  const [show_checkout_modal, setShowCheckoutModal] = useState(false);
  const [plan_to_purchase, setPlanToPurchase] = useState({});
  const [show_cancel_subscription, setCancelSubscription] = useState(false);
  const [subscription, setSubscriptionInfo] = useState(null);

  const { fetchBillingInfos } = useBillingService();
  const { cancelSubscription, fetchSubscription } = useSubscriptionService();

  useEffect(() => {
    fetchSubscription({ query_string: `status=active` }).then(({ subscriptions, error }) => {
      if (error) return toast.error(error);
      setSubscriptionInfo(subscriptions[0]);
    });
  }, []);

  const buySubscription = async () => {
    fetchBillingInfos().then(({ billing_infos, error }) => {
      if (error) return toast.error(error);
      if (!billing_infos.length) {
        return toast.error('Please configure billing information to purchase a plan.');
      }
      setShowConfirmation(true);
    });
  };
  const upgradeSubscription = async () => {};

  const handleConfirmation = (permitted) => {
    if (permitted) {
      setShowPurchaseSub(true);
    }
    setShowConfirmation(false);
  };

  const handlePurchasePlan = (data) => {
    setShowCheckoutModal(true);
    if (data) setPlanToPurchase(data);
  };

  const handleCancelSubscription = async (permitted) => {
    if (permitted) {
      await cancelSubscription(subscription.id);
      setSubscriptionInfo(null);
    }
    setCancelSubscription(false);
  };

  return (
    <>
      <BasicInfoSection title="Plan Details">
        {subscription ? (
          <>
            <DetailItem title="CURRENT PLAN">{subscription.title}</DetailItem>
            <DetailItem title="RENEWAL FREQUENCY">{subscription.renewal_period}</DetailItem>
            <DetailItem title="COST">
              {`${subscription.price.currency.code} ${subscription.price.amount}`}
            </DetailItem>
            <DetailItem title="EXPIRE DATE">
              {new Date(subscription.expires_at).toDateString()}
            </DetailItem>
            <Spacer multiple={2} />
            <Button onClick={upgradeSubscription} text="Upgrade Subscription" />
            <Spacer multiple={4} />
            <a onClick={() => setCancelSubscription(true)} className={cx(styles.linkBtn)}>
              Cancel Subscription
            </a>
          </>
        ) : (
          <>
            <DetailItem title="CURRENT PLAN"> Pay as you use </DetailItem>
            <DetailItem title="RENEWAL FREQUENCY"> N/A </DetailItem>
            <DetailItem title="COST"> NGN {`${rate / 1000} per email sent`}</DetailItem>
            <DetailItem title="EXPIRE DATE">N/A</DetailItem>
            <Spacer multiple={2} />
            <Button onClick={buySubscription} text="Buy Subscription" />
            <Spacer multiple={4} />
            <a className={cx(styles.linkBtn, rate && styles.disabledLink)}> Cancel Subscription </a>
          </>
        )}
      </BasicInfoSection>
      <ConfirmationDialog
        title="Subscription Purchase"
        message="You are about to purchase a new subscription. If you have an active subscription, it would be cancelled."
        callback={handleConfirmation}
        is_open={show_confirmation}
      />
      <GmModal
        title="Subscription Purchase"
        show_title={true}
        show_modal={show_purchase_sub_modal}
        onClose={() => setShowPurchaseSub(false)}
        bodyClassName={styles.wrapper}
      >
        <PurchaseSubscription
          onSave={handlePurchasePlan}
          onClose={() => setShowPurchaseSub(false)}
        />
      </GmModal>
      <GmModal
        title="Payment Checkout"
        show_title={true}
        show_modal={show_checkout_modal}
        onClose={() => setShowCheckoutModal(false)}
        bodyClassName={styles.wrapper}
      >
        <CheckOut plan_info={plan_to_purchase} onClose={() => setShowCheckoutModal(false)} />
      </GmModal>

      <ConfirmationDialog
        title="Cancel Subscription"
        message={
          <p>
            You are about to cancel your subscription. This action would revert your account to the{' '}
            <span style={{ fontWeight: 'bold' }}>pay-as-you-use</span> payment plan. This action
            cannot be undone.
          </p>
        }
        callback={handleCancelSubscription}
        is_open={show_cancel_subscription}
      />
    </>
  );
};
